<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12" class="m-0" style="padding-left: 0px;padding-right: 0px;padding-bottom: 0px;">
          <div class="A4_design_seven mb-1"  style="position: relative;">
            <div class="p-3 bnfertilizer" style="padding-left: 80px !important">
              <div style="margin-top:5px;height: 270px">
                 <b-row>
                  <b-col lg="3" sm="3" class="text-right">
                    <div style="display: none">{{ qrCode() }}</div>
                    <b-col lg="12" md="12" class="text-left d-flex justify-content-center" style="margin-top: 30px">
                      <vue-qrcode v-if="qr_code" :value="qr_code" style="max-width: 100%;max-height: 100%;width: 130px;"/>
                    </b-col>
                  </b-col>
                  <b-col lg="6" sm="6" class="text-center">
                  </b-col>
                  <b-col lg="3" sm="3" class="text-left">
                      <div style="margin-top: 30px;padding-right: 30px !important">
                          <b-img v-if="applicantData.applicant__3136" width='130px' :src="applicantData.applicant__3136" fluid alt="Profile Image"></b-img>
                      </div>
                  </b-col>
                </b-row>
              </div>
              <table border="0" class="mt-1"  style="width:100%;margin-top: 25px">
                <tbody>
                  <tr>
                    <td style="width: 12%" class="text-left" >{{ $t('component_settings.generate_id') }}: </td>
                    <td style="width: 26%" class="text-left p-0 font-weight-bold">DAM - {{ $n(applicantData.generate_id, { useGrouping: false })}} </td>
                    <td style="width: 28%" class="text-right" >{{ $t('fertilizerSales.issue_date') }}: </td>
                    <td style="width: 9%" class="text-right font-weight-bold ">{{ applicantData.issue_date | dateFormat }}</td>
                    <td style="width: 18%"></td>
                  </tr>
                </tbody>
              </table>
              <div style="float:left;width: 100%">
                <table border="0" class="mt-2"  style="width:100%">
                  <tbody>
                    <tr>
                      <td style="width: 52%"  class="text-left" >{{ $t('li_step.applicant_name') }}</td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.applicant__1497 : applicantData.applicant__1807 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 52%"  class="text-left" >{{ $t('li_step.father_or_husband') }}</td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left p-0 ">{{ ($i18n.locale === 'bn') ? applicantData.fathers_na_9299 : applicantData.fathers_na_6391 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 52%"  class="text-left" >{{ $t('globalTrans.mother_name') }}</td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left p-0"> {{ ($i18n.locale === 'bn') ? applicantData.mothers_na_5069 : applicantData.mothers_na_4511 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 52%"  class="text-left" >{{ $t('externalLrcpn.present_address') }}</td>
                      <td style="width: 3%" class="text-left">:</td>
                      <td style="width: 45%" class="text-left p-0">{{ ($i18n.locale === 'bn') ? applicantData.present_ad_5362 : applicantData.present_ad_5848 }} </td>
                    </tr>
                    <tr>
                      <td style="width: 52%;vertical-align: top;" class="text-left">{{ $t('li_step.permanent_address') }}</td>
                      <td style="width: 3%;vertical-align: top;" class="text-left">:</td>
                      <td style="width: 45%; vertical-align: top;" class="text-left">{{ ($i18n.locale === 'bn') ? applicantData.permanent__5521 : applicantData.permanent__7835 }}</td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.business_name') }}</td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                        {{ ($i18n.locale === 'bn') ? applicantData.business_o_1113 : applicantData.business_o_8703 }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.business_address') }}</td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                       {{ ($i18n.locale === 'bn') ? applicantData.addressban_1958 : applicantData.addresseng_3160 }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.nid') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                        {{ $n(applicantData.national_i_6977, { useGrouping: false }) }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.telephone_mobile') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                         {{ applicantData.mobile_num_5699 | mobileNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.business_class') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                        {{ getBusinessClass(applicantData.business_c_2676) }}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.dam_part_1') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                        <span v-if="applicantData.product_ve_4186">
                          <slot v-if="isAdmin">
                            {{ dropDownCheck(applicantData.product_ve_4186, $store.state.licenseRegistration.commonObj.agricultureProductTypeList, $i18n.locale) }}
                          </slot>
                          <slot v-else>
                            {{ dropDownCheck(applicantData.product_ve_4186, $store.state.ExternalLrcpn.lrcpnObj.agricultureProductTypeList, $i18n.locale) }}
                          </slot>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.dam_part_2') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                        <span v-if="applicantData.product_de_4058">
                          <slot v-if="isAdmin">
                            {{ dropDownCheck(applicantData.product_de_4058, $store.state.licenseRegistration.commonObj.agricultureProductList, $i18n.locale) }}
                          </slot>
                          <slot v-else>
                            {{ dropDownCheck(applicantData.product_de_4058, $store.state.ExternalLrcpn.lrcpnObj.agricultureProductList, $i18n.locale) }}
                          </slot>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.dam_market') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                       <span v-if="applicantData.name_of_no_2818">
                          <slot v-if="isAdmin">
                            {{ getColumnName($store.state.licenseRegistration.commonObj.marketList, applicantData.name_of_no_2818) }}
                          </slot>
                          <slot v-else>
                            {{ getColumnName($store.state.ExternalLrcpn.lrcpnObj.marketList, applicantData.name_of_no_2818) }}
                          </slot>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.license_fee') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                       {{ $n(payAmount, { useGrouping: false }) }}/-({{ parseFloat(payAmount) | convertNumberWords }} {{$t('globalTrans.taka ') + ' ' + $t('globalTrans.only')}})
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 52%" class="text-left" >{{ $t('li_step.exp_date') }} </td>
                      <td style="width: 3%" class="text-left p-0">:</td>
                      <td style="width: 45%" class="text-left">
                       {{ applicantData.expire_date | dateFormat }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="footer-cert">
              <div class="col-sm-12 col-md-12 col-lg-12 text-center" style="font-size: 12px">
                <span style="font-weight: bold;">
                {{ ($i18n.locale === 'bn') ? 'ইহা কম্পিউটারে স্বয়ংক্রিয়ভাবে তৈরি সার্টিফিকেট বিধায় স্বাক্ষরের প্রয়োজন নেই।' : 'his is a computer generated certificate, not required any signature.' }}
                </span>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 text-right" style="font-size: 10px">
                {{ ($i18n.locale === 'bn') ? 'মুদ্রণের তারিখ :' : 'Printed Date :' }} {{ today | dateFormat }}
              </div>
            </div>
          </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import VueQrcode from 'vue-qrcode'
import { dateFormat } from '@/Utils/fliter'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['applicantData', 'isAdmin', 'payAmount'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      left_logo: null,
      right_logo: null,
      businessClass: [],
      today: today,
      qr_code: ''
    }
  },
  components: {
    VueQrcode
  },
  created () {
    this.configData()
  },
  methods: {
    configData () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, 'step/report/dam-config').then(response => {
        if (response.success) {
          this.businessClass = response.data
        }
      })
    },
    getBusinessClass (businessClass) {
      const arr = []
        JSON.parse(businessClass).forEach(item => {
          this.businessClass.map((businessClassNames, index) => {
            if (item === index) {
              const businessClassName = this.$i18n.locale === 'en' ? ' ' + businessClassNames.type_name : ' ' + businessClassNames.type_name_bn
              arr.push(businessClassName)
            }
          })
        })
      return arr.toString()
    },
    dropDownCheck (dataValues, dropdownData, locale) {
      dataValues = JSON.parse(dataValues)
      const dataVal = []
      if (dataValues.length > 0) {
          dataValues.forEach((item, key) => {
              const dataFind = dropdownData.find(dt => dt.value === item)
              if (locale === 'bn') {
                  dataVal.push(dataFind.text_bn)
              } else {
                  dataVal.push(dataFind.text_en)
              }
          })
      }
      return dataVal.toString()
  },
    qrCode () {
      this.qr_code = 'মালিকের নাম:' + this.applicantData.applicant__1497 + '; ব্যবসা প্রতিষ্ঠানের নাম:' + this.applicantData.business_o_1113 + '; লাইসেন্স নং:' + 'DAM -' + this.$n(this.applicantData.generate_id, { useGrouping: false }) + '; ইস্যুর তারিখ:' + dateFormat(this.applicantData.issue_date) + '; মেয়াদোত্তীর্ণের তারিখ:' + dateFormat(this.applicantData.expire_date)
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getIsFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    }
  }
}
</script>
<style scoped>
.engfertilizer {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
}
.A4_none {
  width:21cm;
  margin: 20px;
}
.A4_design_seven {
  width:21cm;
  height: 1116px;
  margin: auto !important;
  background: url('../../../assets/images/dam-certi2.jpg');
  background-size: cover;
  padding: 5px 15px 15px 15px;
  /* border: 10px solid transparent;
  border-image: url('../../../../../assets/images/border.png') 30 repeat;
  border-image-width: 14px; */
}
.dotted {
  border-bottom: dotted 2px black;
}
.unc-ldd td {
  padding: 0px;
}
.A4_design_seven .custom-control-inline {
  pointer-events: none !important;
}
.A4_design_seven .bnfertilizer  {
  font-size: 1rem;
  line-height: 36px;
}
.A4_design_seven .bnfertilizer .heading {
  line-height: 27px;
}
.footer-cert {
  position: absolute; bottom: 5px;
  width: 95%;
}
.bnfertilizer td,th {
  line-height: 30px !important;
  vertical-align: top !important;
}
</style>
